import React from 'react'
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

export default function GlovesMaincontent() {
  return (
    <ParallaxProvider>
      <div className="gloves">
        <div className="gloves-pc">
          <ParallaxBanner style={{ aspectRatio: "2 / 1", width: "100%" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/glo/mid-banner.jpg"
              speed={7}
            />
          </ParallaxBanner>
        </div>
        <div className="gloves-mobile">
          <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/glo/mid-banner.jpg"
              speed={10}
            />
          </ParallaxBanner>
        </div>

        <div className="gloves-para">
          <p className="para">
            Lalan Rubbers is the leading Sri Lankan manufacturer and supplier of
            safety gloves for the last 34 years, producing nearly 2 billion
            safety gloves annually. Our products range from disposable gloves,
            household gloves and industrial gloves to unique seamless dipped
            rubber gloves. We have a workforce of 1,800 employees and nine
            state-of-the-art work gloves manufacturing facilities.
            <br />
            <br />
            Our latex gloves are manufactured using centrifuged latex from our
            own rubber plantations; this enables us to provide end consumers
            with products that meet the utmost stringent quality and
            traceability of latex gloves. We also manufacture work gloves using
            Nitrile and Neoprene to cater to our loyal and valued clientele of
            global industrial giants in automobile, oil & gas, medical,
            chemical, mining, food and many other industries.
            <br />
            <br />
            Our sophisticated R&D support and vertically integrated processes,
            including engineered yarn manufacturing for industrial gloves,
            ensure that only the best quality gloves are produced at our
            manufacturing plants. Our quality is certified by our compliance
            with FSC&#8482; -CoC, ISO 9001, Sedex, BRC, GOTS, BSCI, ISO 13485,
            OEKO-TEX, SANITIZED and REACH Standards.
          </p>

          <br />
          <img
            className="fsc-image"
            src="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/plant/fsc_logo_2-2.png"
            style={{ marginTop: "50px" }}
          ></img>
          <br />
          <div className="para-plantaion-link">
            FSC&trade;-certified products are available upon request as the
            website is common for the group:
            <a href="https://www.lalangroup.com/" className="para-plantaion">
              {" "}
              www.lalangroup.com
            </a>
          </div>
          <br />
          <br />

          <p className="address">
            Lalan Rubbers (Private) Limited <br />
            95B, Zone A, Export Processing Zone, <br />
            Biyagama, Malwana, <br />
            Sri Lanka. <br />
            info@lalangroup.com <br />
            (+94) 431 1200 <br />
            (+94) 431 1222 <br />
            <br />
          </p>
        </div>

        <div className="gloves-pc">
          <center>
            <h1 className="bigHugeTitle">
              9 Sectors <br />
              of Integrated <br />
              <span
                style={{
                  color: "#46708a",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Expertise
              </span>
            </h1>
            <div style={{ marginTop: "100px", marginBottom: "200px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <div style={{ width: "2px", background: "#46708a" }}></div>
              </div>
            </div>
          </center>
        </div>

        <div
          className="gloves-mobile"
          style={{ marginTop: "200px", marginBottom: "300px" }}
        >
          <p className="text-max-gloves">
            9 Sectors of Integrated
            <span
              style={{
                color: "#46708a",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Expertise
            </span>
          </p>
        </div>

        <div></div>
      </div>
    </ParallaxProvider>
  );
  }